define("discourse/plugins/discourse-follow/discourse/models/post-stream", ["exports", "@ember/object", "@ember/object/computed", "rsvp", "discourse/lib/ajax", "discourse/models/category", "discourse/models/rest", "discourse-common/utils/decorators"], function (_exports, _object, _computed, _rsvp, _ajax, _category, _rest, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _obj;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  // this class implements an interface similar to the `UserStream` class in core
  // (app/models/user-stream.js) so we can use it with the `{{user-stream}}`
  // component (in core as well) which expects a `UserStream` instance.
  var _default = _exports.default = _rest.default.extend((_dec = (0, _decorators.on)("init"), _dec2 = (0, _decorators.default)("loading", "content.length"), (_obj = {
    loading: false,
    itemsLoaded: 0,
    canLoadMore: true,
    lastPostCreatedAt: (0, _computed.reads)("content.lastObject.created_at"),
    _initialize() {
      this.set("content", []);
    },
    noContent(loading, length) {
      return !loading && length === 0;
    },
    findItems() {
      if (!this.canLoadMore || this.loading) {
        return _rsvp.Promise.resolve();
      }
      this.set("loading", true);
      const data = {};
      if (this.lastPostCreatedAt) {
        data.created_before = this.lastPostCreatedAt;
      }
      return (0, _ajax.ajax)(`/follow/posts/${this.user.username}`, {
        data
      }).then(content => {
        const streamItems = content.posts.map(post => {
          return _object.default.create({
            title: post.topic.title,
            postUrl: post.url,
            created_at: post.created_at,
            category: _category.default.findById(post.category_id),
            topic_id: post.topic.id,
            post_id: post.id,
            post_number: post.post_number,
            username: post.user.username,
            name: post.user.name,
            avatar_template: post.user.avatar_template,
            user_id: post.user.id,
            excerpt: post.excerpt,
            truncated: post.truncated
          });
        });
        return {
          posts: streamItems,
          hasMore: content.extras.has_more
        };
      }).then(_ref => {
        let {
          posts: streamItems,
          hasMore
        } = _ref;
        this.content.addObjects(streamItems);
        this.set("itemsLoaded", this.content.length);
        this.set("canLoadMore", hasMore);
      }).finally(() => {
        this.set("loading", false);
      });
    }
  }, (_applyDecoratedDescriptor(_obj, "_initialize", [_dec], Object.getOwnPropertyDescriptor(_obj, "_initialize"), _obj), _applyDecoratedDescriptor(_obj, "noContent", [_dec2], Object.getOwnPropertyDescriptor(_obj, "noContent"), _obj)), _obj)));
});