define("discourse/plugins/discourse-follow/discourse/components/follow-button", ["exports", "@ember/component", "@ember/object", "@ember/object/computed", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse-common/utils/decorators"], function (_exports, _component, _object, _computed, _ajax, _ajaxError, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _obj;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  var _default = _exports.default = _component.default.extend((_dec = (0, _decorators.default)("user", "currentUser"), _dec2 = (0, _decorators.default)("isFollowed", "canFollow"), _dec3 = (0, _decorators.default)("isFollowed", "canFollow"), (_obj = {
    loading: false,
    isFollowed: (0, _computed.alias)("user.is_followed"),
    canFollow: (0, _computed.alias)("user.can_follow"),
    showButton(user, currentUser) {
      if (!currentUser) {
        return false;
      }
      if (currentUser.id === user.id) {
        return false;
      }
      if (user.suspended) {
        return false;
      }
      if (user.staged) {
        return false;
      }
      if (user.id < 1) {
        // bot
        return false;
      }
      return true;
    },
    labelKey(isFollowed, canFollow) {
      if (isFollowed && canFollow) {
        return "follow.unfollow_button_label";
      } else {
        return "follow.follow_button_label";
      }
    },
    icon(isFollowed, canFollow) {
      if (isFollowed && canFollow) {
        return "user-times";
      } else {
        return "user-plus";
      }
    },
    toggleFollow() {
      const type = this.isFollowed ? "DELETE" : "PUT";
      this.set("loading", true);
      (0, _ajax.ajax)(`/follow/${this.user.username}.json`, {
        type
      }).then(() => {
        this.set("isFollowed", !this.isFollowed);
      }).catch(_ajaxError.popupAjaxError).finally(() => {
        this.set("loading", false);
      });
    }
  }, (_applyDecoratedDescriptor(_obj, "showButton", [_dec], Object.getOwnPropertyDescriptor(_obj, "showButton"), _obj), _applyDecoratedDescriptor(_obj, "labelKey", [_dec2], Object.getOwnPropertyDescriptor(_obj, "labelKey"), _obj), _applyDecoratedDescriptor(_obj, "icon", [_dec3], Object.getOwnPropertyDescriptor(_obj, "icon"), _obj), _applyDecoratedDescriptor(_obj, "toggleFollow", [_object.action], Object.getOwnPropertyDescriptor(_obj, "toggleFollow"), _obj)), _obj)));
});